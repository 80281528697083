import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
const BlogTeaserArticle = styled.article`
  background-color: #2b2a2a;
  border: 2px #917147 solid;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: 0.3s background-color;
  overflow: hidden;

  &:hover {
    background-color: #3a3a3a;

    span {
      text-decoration: underline;
    }
  }
`

const BlogTeaserDetails = styled.div`
  padding: 20px 20px 10px;
`

const BlogTeaserLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 700;

  h2 {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 30px;
      height: 2px;
      background-color: #917147;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const BlogTeaserCb = styled.div`
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 700;

  h2 {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 30px;
      height: 2px;
      background-color: #917147;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const BlogTeaserClick = styled.span`
  text-align: right;
  width: 100%;
  display: block;
  font-size: 14px;
`

const Image = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const BlogTeaser = ({ data, slug = '', cb = () => {}, location = false }) => {
  const { main_image = '', title } = data

  // const locImage = location && title === 'hove' ? require(`../../media/images/hove_photo_1.jpg`).default : require(`../../media/images/flansham_photo_1.jpg`).default
  const url = main_image !== null ? main_image.url : require(`../../media/images/basic_layout_placeholder_image_1.png`).default
  return (
    <BlogTeaserArticle className="blog-teaser">
      {slug !== '' ? (
        <BlogTeaserLink to={slug}>
          {url !== '' && <Image style={{ backgroundImage: `url(${url})` }} />}
          <BlogTeaserDetails>
            <h2>{title}</h2>
            <BlogTeaserClick className="blog-teaser__click">Read More</BlogTeaserClick>
          </BlogTeaserDetails>
        </BlogTeaserLink>
      ) : (
        <BlogTeaserCb
          onClick={() => {
            cb(data.id)
          }}
        >
          {url !== '' && <Image style={{ backgroundImage: `url(${url})` }} />}
          <BlogTeaserDetails>
            <h2>{title}</h2>
            <BlogTeaserClick className="blog-teaser__click" />
          </BlogTeaserDetails>
        </BlogTeaserCb>
      )}
    </BlogTeaserArticle>
  )
}

export default BlogTeaser
