import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Vid from '../media/videos/location_hove.mp4'
import VidPoster from '../media/videos/location_hove_poster.jpg'
import HeaderImage from '../components/headerImage'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderVideo from '../components/headerVideo'
import BlogTeaser from '../components/blog/blogTeaser'

// import templates
import BasicLayout from '../templates/basic-layout'

import styled from 'styled-components'
const VideoContainer = styled.div`
  background-color: whitesmoke;
  line-height: 0;
  padding: 0;
  margin: 40px 0;
`

const VideoRatio = styled.div`
  position: relative;
  padding-top: 52.8%;
`

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const BlogList = styled.ul`
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  margin-left: -15px;
  margin-right: -15px;

  margin-top: 80px;
  margin-bottom: 60px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const BlogItem = styled.li`
  flex-basis: 100%;
  margin: 10px;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(50% - 20px);
  }
`

const seo_description = `Luxury state of the art one-on-one studio. Gives you a bespoke workout in absolute privacy. Location on Boundary Road, Hove.`
const seo_keywords = `Private Studio Hove, Luxury state of the art, 1-on-1 studio workout, private sessions Brighton, long term injury, located in Hove, Virtual online training sessions, fitness training, NHS approved, Virtual Personal Training Steyning & Hove, personal training, 1-on-1 workouts, affordable prices, Online, personal trainer Steyning, personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove, Steyning`

const Location = () => {
  const data = useStaticQuery(query)
  const { strapiLocation, allStrapiIndividualLocation } = data
  const { title, description, SEO } = strapiLocation

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO
  return (
    <Layout>
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderImage src={require(`../media/images/basic_layout_placeholder_image_1.png`).default} title={title} />
      <BasicLayout pageName={title} description={description} rightImage={''} images={[]} />
      <div className="container">
        <BlogList>
          {allStrapiIndividualLocation.nodes.length > 0 &&
            allStrapiIndividualLocation.nodes.map((node, index) => {
              return (
                <BlogItem key={index}>
                  <BlogTeaser data={node} slug={`/location/${node.slug}`} location={true} />
                </BlogItem>
              )
            })}
        </BlogList>
      </div>
    </Layout>
  )
}

const query = graphql`
  {
    strapiLocation {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
    allStrapiIndividualLocation {
      nodes {
        title
        slug
        main_image {
          url
        }
      }
    }
  }
`

export default Location
